import { useUserStore } from '@st/user/stores/useUserStore'

export const useBonusesStore = defineStore('bonuses', () => {
  const { data, execute: fetchBonusCounters } = useStFetch('/bonus/find', {
    method: 'post',
    body: {
      params: {
        expired: false,
        claimed: false,
        bonusProgramType: ['ratRace', 'cashback', 'rakeBack', 'reload'],
      },
      pagination: {
        perPage: 1,
        orderBy: [
          {
            fieldName: 'id',
            sortOrder: 'ASC',
          },
        ],
      },
    },
    immediate: false,
    watch: false,
  })

  const { isAuthenticated } = storeToRefs(useUserStore())
  const bonusesCount = ref(0)
  async function updateBonusCounters() {
    if (isAuthenticated.value) {
      await fetchBonusCounters()
      bonusesCount.value = data.value?.paging.total ?? 0
    } else bonusesCount.value = 0
  }

  /**
   * запрашивает каунтеры при наличии юзера
   * сбрасывает в 0 при отсутствии
   */
  watch(
    () => isAuthenticated.value,
    () => {
      updateBonusCounters()
    },
    { immediate: true },
  )

  return {
    bonusesCount,
    updateBonusCounters,
  }
})
